/** This file contains helpers for the StyledComponent library for ReactJS.
 * Styled components helpers
 * @author Joseph Julius Owonvown
 */
import { isArray } from "lodash"
import { css } from "styled-components"
import {
  curry,
  compose,
  map,
  prepend,
  split,
  lensPath,
  view,
  prop,
} from "ramda"

export const theme = curry((key, props) => {
  const path = isArray(key) ? key : split(".", key)
  const getPath = view(lensPath(path))

  return getPath(props.theme)
})

export const themeOr = curry((fallback, key, props) => {
  const keyFrom = theme(key)
  return keyFrom(props) || keyFrom({ theme: fallback })
})

export const withProp = curry((key, style, props) => {
  return props[key] && css(...style)
})

export const propIs = curry(
  (prop, functor, style, props) => functor(props[prop]) && style
)

export const propOr = (prop, defValue) => props => props[prop] || defValue

export const fullWidth = withProp(
  "fullwidth",
  css`
    display: flex;
    width: 100%;
  `
)

const sizes = {
  bigDestktop: 1800, // 1800 above
  macScreen: 1440,
  tabletLand: 1200,
  tabletPort: 900,
  phone: 600,
}

const rulesAddRule = ({ property, size }) => {
  return `(${property}: ${size / 16}em)`
}

const makeMediaQuery = media_rules => (...args) => {
  return css`
    @media ${media_rules.join(" and ")} {
      ${css(args)}
    }
  `
}

// Iterate through the sizes and create a media template
export const media = (property = "min-width") => {
  const mediaQueries = {}
  for (const [screen, viewWidth] of Object.entries(sizes)) {
    const mediaParam = rulesAddRule({ property, size: viewWidth })
    mediaQueries[screen] = makeMediaQuery(["screen", mediaParam])
    mediaQueries[screen].rule = mediaParam
  }
  return mediaQueries
}

media.min = media("min-width")
media.max = media("max-width")
media.concat = compose(makeMediaQuery, prepend("screen"), map(prop("rule")))

export const mergeClass = (...args) =>
  args.filter(e => typeof e === "string").join(" ")

export const cx = mergeClass
